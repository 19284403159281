import styles from './ra-badge.module.scss'
import clsx from 'clsx'

interface RaBadgeProps {
  badge: number | '+' | '-'
  color?: string
  badgeColor?: string
  withBorder?: boolean
}

export const RaBadge = ({
  badge,
  color = '#FFF',
  badgeColor = 'var(--color-primary)',
  withBorder = false,
}: RaBadgeProps) => {
  return (
    <div
      className={clsx(
        styles['ra-badge'],
        withBorder && styles['ra-badge--with-border']
      )}
      style={{ color, borderColor: color, backgroundColor: badgeColor }}>
      <span
        style={{ color }}
        className={styles['ra-badge__content']}>
        {badge}
      </span>
    </div>
  )
}
