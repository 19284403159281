'use client'

import { createContext, useContext, useEffect, useMemo } from 'react'
import { stripFrontendUrl, unescapeHTML } from '@core-helpers'

import { LOCAL_STORAGE } from '@constants/local-storage.constants'
import { SettingsContextProps } from '@typings'
import { setLocalStorage } from '@utilities/helpers/core.helpers'
import { Loop } from '@utilities/helpers/core.helpers'

export const SettingsContext = createContext<SettingsContextProps>(null)

export const SettingsContextProvider = ({ initial, children }) => {
  const breadcrumbs = useMemo(() => {
    const yoastBreadcrumbs = initial?.page?.yoast_json_ld?.[0]?.[
      '@graph'
    ]?.find(item => item['@type'] === 'BreadcrumbList')

    if (!yoastBreadcrumbs) return null
    const count = yoastBreadcrumbs?.itemListElement?.length
    const items = Loop(
      yoastBreadcrumbs?.itemListElement,
      (breadcrumb, index) => {
        // Older yoast version
        if (!breadcrumb?.item?.url) {
          return {
            link: {
              title: unescapeHTML(breadcrumb?.name),
              url: stripFrontendUrl(breadcrumb?.item),
            },
            disabled: !breadcrumb.item,
          }
        } else {
          // Newer yoast version
          return {
            link: {
              title: unescapeHTML(breadcrumb?.item?.name),
              url: stripFrontendUrl(breadcrumb?.item?.url),
            },
            disabled: count - 1 == index,
          }
        }
      }
    )

    return items
  }, [])

  useEffect(() => {
    if (initial?.settings?.translations) {
      setLocalStorage(LOCAL_STORAGE.TRANSLATIONS, initial.settings.translations)
      setLocalStorage(LOCAL_STORAGE.WP_URL, initial.settings.WP_URL)
      setLocalStorage(LOCAL_STORAGE.FE_URL, initial.settings.FE_URL)
    }
  }, [initial])

  return (
    <SettingsContext.Provider
      value={{
        GTM_ID: initial.GTM_ID,
        ROLLBAR_TOKEN: initial.ROLLBAR_TOKEN,
        ROLLBAR_ENV: initial.ROLLBAR_ENV,
        themeSettings: initial.settings,
        destination: initial?.site,
        params: initial?.params,
        yoast_breadrcrumbs: breadcrumbs,
      }}>
      {children}
    </SettingsContext.Provider>
  )
}

export const useSettings = () => {
  return useContext(SettingsContext)
}

SettingsContext.displayName = 'SettingsContext'
